import { axiosInstance } from "./axiosConfig";

export const requestGetUserById = (idUser: string) =>
  axiosInstance.get(`/api/users/${idUser}`);

export const requestGetUserProfile = () =>
  axiosInstance.get(`/api/users/userprofile`);

export const requestGetUserEdificios = () =>
  axiosInstance.get(`/api/users/edificios`);
