import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportesScopes } from "../../../utils/reportesScope";
import { ReporteState, IReporte } from "./types"; // Asegúrate de importar los tipos adecuados

const initialState: ReporteState = {
  reporteByIdLoading: false,
  reporteNuevoLoading: false,
  reportesLoading: false,
  reporteById: null,
  reporteByIdFail: false,
  reporteNuevo: null,
  reportesByEdificio: [],
  reportesUnread: [],
  message: "",
  errorMessage: "",
  scope: ""
};

const reporteSlice = createSlice({
  name: "reporte",
  initialState,
  reducers: {
    postReporteRequest: (
      state,
      action: PayloadAction<{
        reporte: FormData;
        idEdificio: any
      }>
    ) => {
      state.errorMessage = "";
      state.reporteNuevoLoading = true;
      state.scope = ReportesScopes.CREAR_REPORTE;
      state.message = "";
    },
    postReporteSuccess: (state, action: PayloadAction<{ reporte: IReporte; scope: string; message: string }>) => {
      state.reporteNuevoLoading = false;
      state.reporteNuevo = action.payload.reporte;
      state.scope = action.payload.scope;
      state.message = action.payload.message;
      state.errorMessage = "";
    },
    postReporteFail: (state, action: PayloadAction<{ message: string; scope: string }>) => {
      state.reporteNuevoLoading = false;
      state.message = "";
      state.errorMessage = action.payload.message;
      state.scope = action.payload.scope;
    },
    getReportesByEdificio: (state, action: PayloadAction<{idEdificio: string }>) => {
      state.reportesLoading = true;
      state.message = "";
      state.errorMessage = "";
      state.scope = ReportesScopes.LISTA_REPORTES;
    },
    setReportesByEdificio: (state, action: PayloadAction<{ reportes: IReporte[]; message: string; scope: string }>) => {
      state.reportesByEdificio = action.payload.reportes;
      state.reportesLoading = false;
      state.message = action.payload.message;
      state.errorMessage = "";
      state.scope = action.payload.scope;
    },
    setReportesByEdificioFail: (state, action: PayloadAction<{ message: string; scope: string }>) => {
      state.reportesLoading = false;
      state.message = "";
      state.errorMessage = action.payload.message;
      state.scope = action.payload.scope;
    },
    getReporteByIdRequest: (state, action: PayloadAction<{id: string }>) => {
      state.reporteById = null;
      state.reporteByIdFail = false;
      state.reporteByIdLoading = true;
      state.errorMessage = "";
      state.message = "";
      state.scope = ReportesScopes.DETALLE_REPORTE;
    },
    getReporteByIdSuccess: (state, action: PayloadAction<{ reporte: IReporte; message: string }>) => {
      state.reporteByIdLoading = false;
      state.reporteById = action.payload.reporte;
      state.message = action.payload.message;
      state.scope = ReportesScopes.DETALLE_REPORTE;
    },
    getReporteByIdFail: (state, action: PayloadAction<{ message: string }>) => {
      state.reporteByIdFail = true;
      state.reporteByIdLoading = false;
      state.errorMessage = action.payload.message;
      state.scope = ReportesScopes.DETALLE_REPORTE;
    },
    deleteReporteRequest: (state, action: PayloadAction<{  id: string; scope: string }>) => {
      state.errorMessage = "";
      state.message = "";
      state.scope = action.payload.scope;
    },
    deleteReporteSuccess: (state, action: PayloadAction<{ id: string; message: string; scope: string }>) => {
      if (action.payload.scope === ReportesScopes.DETALLE_REPORTE) {
        state.reporteById = null;
        state.message = action.payload.message;
        state.errorMessage = "";
      } else if (action.payload.scope === ReportesScopes.LISTA_REPORTES) {
        state.reportesByEdificio = state.reportesByEdificio.filter(
          (reporte) => reporte._id !== action.payload.id
        );
        state.message = action.payload.message;
        state.errorMessage = "";
      } else {
        state.message = action.payload.message;
        state.errorMessage = "";
      }
    },
    deleteReporteFail: (state, action: PayloadAction<{ message: string; scope: string }>) => {
      state.errorMessage = action.payload.message;
      state.message = "";
      state.scope = action.payload.scope;
    },
    clearMessage: (state) => {
      state.message = "";
      state.errorMessage = "";
      state.scope = "";
    }
  }
});

export const {
  postReporteRequest,
  postReporteSuccess,
  postReporteFail,
  getReportesByEdificio,
  setReportesByEdificio,
  setReportesByEdificioFail,
  getReporteByIdRequest,
  getReporteByIdSuccess,
  getReporteByIdFail,
  deleteReporteRequest,
  deleteReporteSuccess,
  deleteReporteFail,
  clearMessage
} = reporteSlice.actions;

export default reporteSlice.reducer;