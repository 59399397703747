
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonBackButton,
  IonButtons,
  useIonViewWillEnter,
  isPlatform
} from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import { RootState } from "../../store/ducks/rootReducer";
import "../Dashboard/Dashboard.css";
import "../../styles/App.css";
import { RouteComponentProps } from "react-router";

type Params = { idEdificio: string };

const MemoriasCopList: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { idEdificio },
    },
}) => {
  const { _id, userProfile } = useSelector((state: RootState) => state.users);
  return (
    <IonPage className="bg-color-grey">
      <IonHeader className="ion-no-border border-header">
         <ToolbarBack title="Memorias"  backLink={`/copropietario/tabs/${idEdificio}/escritorio`} />
      </IonHeader>

      <IonContent fullscreen className="ion-content-grey"></IonContent>
    </IonPage>
  );
};

export default MemoriasCopList;