import {
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonRouter,
    useIonViewDidEnter,
    useIonViewWillEnter,
} from "@ionic/react";
import "../../styles/App.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect } from "react";
import { constructOutline, chatbubbleEllipsesOutline, businessOutline, documentTextOutline, walletOutline, megaphoneOutline, albumsOutline, imagesOutline, buildOutline, alert, bookOutline } from "ionicons/icons";
import { getEdificioCopropietario } from "../../store/ducks/edificios/actions";
import ToolbarMenu from "../../components/Toolbars/ToolbarMenu";
import EscritorioItem from "../../components/Copropietario/Escritorio/EscritorioItem";
import EscritorioItemGestion from "../../components/Copropietario/Escritorio/EscritorioItemGestion";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import { RouteComponentProps } from "react-router";
import ToolbarMainMenu from "../../components/Toolbars/ToolbarMainMenu";
import MainMenu from "../../components/Menus/MainMenuCopropietario";

type Params = { idEdificio: string };

const EscritorioCopropietario: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { idEdificio },
    },
}) => {
    const { edificioCopropietario } = useSelector((state: RootState) => state.edificios);
    const { isLogged, tipo, checkAuthSuccess } = useSelector((state: RootState) => state.users);
    const informeReciente: any = edificioCopropietario?.informes ? edificioCopropietario.informes[0] : undefined;
    const obraReciente: any = edificioCopropietario?.obras ? edificioCopropietario.obras[0] : undefined;
    const dispatch = useDispatch();

 

    useEffect(() => {
        if (!edificioCopropietario?._id) dispatch(getEdificioCopropietario());
    }, [edificioCopropietario?._id, isLogged]);

    // Cuando la vista está a punto de entrar
    useIonViewWillEnter(() => {
        if (!edificioCopropietario?._id) dispatch(getEdificioCopropietario());
    });

    return (

        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarMainMenu title="Edificio" />
            </IonHeader>
            <IonContent fullscreen className="ion-content-grey">
                {!edificioCopropietario?._id ? (
                    <div className="center-content">
                        <CustomSpinner color="primary" />
                    </div>
                ) : (
                    <>

                        <IonGrid>
                            <IonRow className="dashboard-row">
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItemGestion
                                        title="Reportes de desperfectos"
                                        link={`/copropietario/tabs/${idEdificio}/reportes`}
                                        iconName={alert}
                                        displayCantidad={false}
                                        descripcion="Informa desperfectos edilicios para que la administración y copropietarios estén al tanto y coordinen la solución"
                                    />
                                </IonCol>

                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItemGestion
                                        title="Avisos"
                                        link={`/copropietario/tabs/${idEdificio}/avisos`}
                                        iconName={megaphoneOutline}
                                        displayCantidad={false}
                                        descripcion="Publica noticias o recordatorios importantes para mantener informados a los copropietarios y coordinar acciones"
                                    />

                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItemGestion
                                        title="Servicios"
                                        link={`/copropietario/servicios/${idEdificio}/lista`}
                                        iconName={buildOutline}
                                        displayCantidad={false}
                                        descripcion="Incluye datos, horarios y tareas de cada servicio, permitiendo a todos seguir su estado y asegurar su correcta ejecución"
                                    />

                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItemGestion
                                        title="Registros"
                                        link={`/copropietario/registros/${idEdificio}/lista`}
                                        iconName={albumsOutline}
                                        displayCantidad={false}
                                        descripcion="Registra documentación que consideren relevante, aportando transparencia y control en cada actividad compartida"
                                    />
                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItemGestion
                                        title="Planos"
                                        link={`/copropietario/planos/${idEdificio}/lista`}
                                        iconName={imagesOutline}
                                        displayCantidad={false}
                                        descripcion="Comparte planos de mensura y permisos de construcción para el acceso y revisión de la información edilicia"
                                    />

                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItemGestion
                                        title="Reglamento Interno"
                                        link={`/copropietario/reglamento/${idEdificio}/lista`}
                                        iconName={bookOutline}
                                        displayCantidad={false}
                                        descripcion="Comparte el reglamento interno, facilitando el cumplimiento de normas que contribuyen al mantenimiento edilicio"
                                    />

                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItemGestion
                                        title="Consultas"
                                        link={`/copropietario/consultas/${idEdificio}/lista`}
                                        iconName={chatbubbleEllipsesOutline}
                                        displayCantidad={false}
                                        descripcion="Envía consultas al equipo técnico para resolver problemas edilicios y obtener asesoramiento especializado sobre posibles soluciones"
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow className="dashboard-row"></IonRow>
                        </IonGrid>
                        <IonGrid className="pb-50">

                            <IonRow className="dashboard-row">
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItem
                                        title="Obras"
                                        cantidad={edificioCopropietario?.obras?.length.toString()}
                                        link={`/copropietario/tabs/${idEdificio}/obras`}
                                        iconName={businessOutline}
                                        displayCantidad={true}
                                        fechaPublicacion={obraReciente?.updatedAt}
                                    />
                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItem
                                        title="Informes técnicos"
                                        cantidad={edificioCopropietario?.informes?.length.toString()}
                                        link={`/copropietario/informes/${idEdificio}/lista`}
                                        iconName={documentTextOutline}
                                        fechaPublicacion={informeReciente?.updatedAt}
                                        displayCantidad={true}
                                    />
                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItem
                                        title="Memorias Constructivas"
                                        cantidad={edificioCopropietario?.memorias?.length.toString()}
                                        link={`/copropietario/memorias/${idEdificio}/lista`}
                                        iconName={constructOutline}
                                        displayCantidad={true}

                                    />
                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <EscritorioItem
                                        title="Presupuestos"
                                        cantidad={edificioCopropietario?.presupuestos?.length.toString()}
                                        link={`/copropietario/presupuestos/${idEdificio}/lista`}
                                        iconName={walletOutline}
                                        displayCantidad={true}
                                    />
                                </IonCol>

                            </IonRow>
                        </IonGrid>
                    </>
                )}
            </IonContent>
        </IonPage>

    );
};

export default EscritorioCopropietario;