import React, { useEffect } from "react";
import {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
    IonHeader,
    IonToolbar,
    useIonRouter,
} from "@ionic/react";
import { homeOutline, personOutline, logOutOutline, gridOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "../../utils/auth";
import { logout, logoutSuccess } from "../../store/ducks/users/actions";
import { RootState } from "../../store/ducks/rootReducer";

const MainMenuCopropietario: React.FC = () => {
    const navigation = useIonRouter();
     const { isLogged } = useSelector((state: RootState) => state.users);
    const dispatch = useDispatch();

    function salir() {
        dispatch(logout());
    }
    
  

    return (
        <IonMenu contentId="main-content-copropietario" side="start">
            <IonHeader className="ion-no-border border-header">
                <IonToolbar className="menu-toolbar d-flex">
                    <div className="ion-logo-splitPane"></div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList lines="none">
                    <IonItem routerLink="/copropietario/tabs" routerDirection="root" className="main-menu-item">
                        <IonIcon icon={gridOutline} slot="start" className="font-dark-grey"/>
                        <IonLabel>Escritorio</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/copropietario/perfil" routerDirection="forward" className="main-menu-item">
                        <IonIcon icon={personOutline} slot="start" className="font-dark-grey"/>
                        <IonLabel>Perfil</IonLabel>
                    </IonItem>
                    <IonItem
                        button
                        onClick={() => {
                            salir()
                        }}
                        className="main-menu-item"
                    >
                        <IonIcon icon={logOutOutline} slot="start" className="font-dark-grey"/>
                        <IonLabel>Salir</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default MainMenuCopropietario;
