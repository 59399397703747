export const PRIORIDADES = ["baja", "media", "alta"];
export const ESTADOS = ["activo", "resuelto", "archivado"];
export const CATEGORIAS = [
    "mantenimiento",
    "evento",
    "normas",
    "pagos",
    "seguridad",
    "areas_comunes",
    "perdidos_y_encontrados"
];
export const TIPO_USUARIOS = [
    "copropietario",
    "administración",
    "tecnico",
    "todos"
];