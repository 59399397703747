import {  Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/ducks/rootReducer";
import CenteredLoader from "../components/CenteredLoader/CenteredLoader";


const LoginRoute = ({ component: Component, userHomePath, ...rest }: any) => {
  const {
    isLogged,
    checkingAuth,
    loginLoading,
    checkingLogout
  } = useSelector((state: RootState) => state.users);


  return (
    <>
      {checkingAuth || checkingLogout || loginLoading ? (
        <CenteredLoader isOpen={checkingAuth || checkingLogout || loginLoading} message="Verificando autenticación..." />
      ) : !isLogged ? (
        <Route {...rest} component={Component} />
      ): <><CenteredLoader isOpen={true} message="Cargando..." /> {console.log("login route")}</>
       
      }
    </>
  );
};

export default LoginRoute;