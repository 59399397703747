import React, { useEffect, useState, useCallback } from 'react';
import { IonToast, IonRouterOutlet, useIonRouter} from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";
import { Route } from "react-router-dom";
import LoginRoute from "../routes/LoginRoute";
import PrivateRoute from "../routes/PrivateRoute";
import { useDispatch, useSelector } from 'react-redux';
import { checkAuthStatus, getUserProfileRequest, logout, resetNewContentAvailable } from '../store/ducks/users/actions';
import { App as CapacitorApp } from '@capacitor/app';
import type { PluginListenerHandle } from '@capacitor/core';
import { RootState } from '../store/ducks/rootReducer';
import Login from './Login/Login';
import { getUserId, isTokenExpired } from '../utils/auth';
import ObrabitPresentation from './ObrabitPresentacion/ObrabitPresentacion';

import PoliticaPrivacidad from './PoliticaPrivacidad/PoliticaPrivacidad';
import TabsCopropietario from './TabsCopropietario/TabsCopropietario';
import InformesCopRoutes from '../routes/InformesCopRoutes';
import MemoriasCopRoutes from '../routes/MemoriasCopRoutes';
import PresupuestosCopRoutes from '../routes/PresupuestosCopRoutes';
import ServiciosCopRoutes from '../routes/ServiciosCopRoutes';
import ReglamentoCopRoutes from '../routes/ReglamentoCopRoutes';
import PlanosCopRoutes from '../routes/PlanosCopRoutes';
import RegistrosCopRoutes from '../routes/RegistrosCopRoutes';
// Carga diferida
//const MenuCopropietario = React.lazy(() => import('./MenuCopropietario/MenuCopropietario'));
const Main: React.FC = () => {
    const { isLogged, tipo, loginLoading, idEdificioCopropietario, newContentAvailable, checkAuthFail, isLoggedOut, checkingAuth, checkingLogout, userProfile, checkAuthSuccess } = useSelector((state: RootState) => state.users);
    const [profileLoaded, setProfileLoaded] = useState(false);
    const [toastIsOpen, setToastIsOpen] = useState(false);
    const [lastCheckedPath, setLastCheckedPath] = useState<string | null>(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigation = useIonRouter();
    const history = useHistory();

    const pathByType = (tipo: string | "") => {

        if (tipo === "copropietario") return "/copropietario/tabs";
        if (tipo === "administración") return "/administracion/tabs";
        if (tipo === "tecnico") return "/tecnico/tabs";
        return "/";
    };

    const userId = getUserId();
    useEffect(() => {
        if (isTokenExpired()) {
            dispatch(logout());
        };
    }, [isTokenExpired(), location.pathname]);

    useEffect(() => {
        if ((isLogged === false || (!isLogged && userId === "")) && location.pathname !== "/" && !checkingAuth) {
            history.replace("/");
        };
        if (!isLogged && !checkingAuth && !checkingLogout && !loginLoading && userId !== "") {
            dispatch(checkAuthStatus());
        };
        if ((isLogged) && location.pathname === "/") {
            history.replace(`${pathByType(tipo)}`);
        };
    }, [isLogged]);



    useEffect(() => {
        let backButtonListener: PluginListenerHandle | null = null;

        const setupListener = async () => {
            backButtonListener = await CapacitorApp.addListener('backButton', ({ canGoBack }) => {
                if (!canGoBack) {
                    CapacitorApp.exitApp();
                }
            });
        };
    
        setupListener();
    
        return () => {
            if (backButtonListener) {
                backButtonListener.remove();
            }
        };
    }, []);

    const reloadPage = useCallback(() => {
        window.location.reload();
    }, []);

    const handleReload = () => {
        setToastIsOpen(false);
        dispatch(resetNewContentAvailable());
        reloadPage();
    };

    useEffect(() => {
        // If profile is not loaded, request it
        if (location.pathname !== '/') {
            if (newContentAvailable) {
                setToastIsOpen(true);
            }
        }
    }, [newContentAvailable, location]);

    useEffect(() => {
        if (!profileLoaded && isLogged) {
            dispatch(getUserProfileRequest());
            setProfileLoaded(true);
        } else if (!isLogged) {
            setProfileLoaded(false);
        }
    }, [isLogged, profileLoaded]);


    return (

        <>
            <IonRouterOutlet>
                <Route exact path="/servicios" component={ObrabitPresentation} />
                <Route exact path="/privacidad" component={PoliticaPrivacidad} />
                <LoginRoute exact path="/" component={Login} userHomePath={pathByType(tipo)} />
                <PrivateRoute path="/copropietario/tabs" component={TabsCopropietario} />
                {InformesCopRoutes}
                {MemoriasCopRoutes}
                {PresupuestosCopRoutes}
                {ServiciosCopRoutes}
                {RegistrosCopRoutes}
                {PlanosCopRoutes}
                {ReglamentoCopRoutes}
            </IonRouterOutlet>

            <IonToast
                isOpen={toastIsOpen}
                message="Una nueva actualización de la aplicación se encuentra disponible. Acepte para continuar."
                onDidDismiss={() => setToastIsOpen(false)}
                layout="stacked"
                buttons={[
                    {
                        text: 'Aceptar',
                        handler: handleReload
                    },
                    {
                        text: 'Cancelar',
                        role: 'cancel'
                    }
                ]}
            />
        </>


    );
};

export default Main;