import React, { useEffect, useState } from "react";

import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    useIonViewWillEnter,
} from "@ionic/react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";

import { RouteComponentProps } from "react-router";

import { list, briefcase, images, reader, documentText, cash, analytics } from "ionicons/icons";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";

import { getObraById } from "../../store/ducks/obras/actions";
import ObraItem from "../../components/Copropietario/Obras/ObraItem";
import ObraDescripcionCard from "../../components/Copropietario/Obras/ObraDescripcionCard";
import CustomSpinner from "../../components/Spinner/CustomSpinner";

type Params = { obraId: string, idEdificio: string };

const ObraCopDetail: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { obraId, idEdificio },
    },
}) => {

    const { obraById } = useSelector((state: RootState) => state.obras);

    const [obraLoad, setObraLoad] = useState(false);
    const dispatch = useDispatch();

    // Cuando la vista está a punto de entrar
    useIonViewWillEnter(() => {
        // Inicializa obraLoad como false al entrar en la vista
        setObraLoad(false);
        // Hace el dispatch para obtener el nueva obra con la nueva obraId
        dispatch(getObraById(obraId));
    });

    // Cuando la nueva obra se carga completamente, cambiar obraLoad a true
    useEffect(() => {
        if (typeof obraById === 'object' && obraById !== null && '_id' in obraById && obraById._id === obraId) {
            // Verifica si obra cargada en Redux coincide con la obraIdId actual
            setObraLoad(true);
        }
    }, [obraById, obraId]);

    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title="Obra" backLink={`/copropietario/tabs/${idEdificio}/obras`} />
            </IonHeader>
            {(!obraLoad) ? (
                <IonContent fullscreen className="ion-content-grey">
                    <div className="center-content">
                        <CustomSpinner color="primary" />
                    </div>
                </IonContent>
            ) : (<IonContent fullscreen className="ion-content-grey">


                <IonGrid >
                    <IonRow className="dashboard-row">
                        <IonCol size-md="12" size-lg="12" size-xs="12" size-sm="12">
                            <ObraDescripcionCard
                                obra={obraById}
                            />
                        </IonCol>
                    </IonRow>
                    {/* </IonGrid>
                        <IonGrid className="pb-50"> */}
                    <IonRow className="dashboard-row">
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <ObraItem
                                link={`/copropietario/tabs/${idEdificio}/obras/seguimiento/${obraId}`}
                                title="Seguimiento"
                                subTitle="Visitas a obra"
                                iconName={analytics}
                            />
                        </IonCol>
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <ObraItem
                                link={`/copropietario/tabs/${idEdificio}/obras/notas/${obraId}`}
                                title="Notas"
                                subTitle="Anotaciones relevantes"
                                iconName={list}
                            />
                        </IonCol>
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <ObraItem
                                link={`/copropietario/tabs/${idEdificio}/obras/empresa/${obraId}`}
                                title="Constructora"
                                subTitle="Empresa contratada"
                                iconName={briefcase}
                            />
                        </IonCol>
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <ObraItem
                                link={`/copropietario/tabs/${idEdificio}/obras/presupuestos/${obraId}`}
                                title="Presupuestos"
                                subTitle="Presupuestos presentados"
                                iconName={cash}
                            />
                        </IonCol>
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <ObraItem
                                link={`/copropietario/tabs/${idEdificio}/obras/memoria/${obraId}`}
                                title="Memoria"
                                subTitle="Detalles técnicos"
                                iconName={documentText}
                            />
                        </IonCol>
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <ObraItem
                                link={`/copropietario/tabs/${idEdificio}/obras/informes/${obraId}`}
                                title="Informes"
                                subTitle="Informes asociados"
                                iconName={reader}
                            />
                        </IonCol>
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <ObraItem
                                link={`/copropietario/tabs/${idEdificio}/obras/recaudos/${obraId}`}
                                title="Gráficos"
                                subTitle="Planos y detalles"
                                iconName={images}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>)}
        </IonPage>
    );
};

export default ObraCopDetail;