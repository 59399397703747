import PrivateRoute from "./PrivateRoute";
import InformeCopList from "../pages/InformesCopropietario/InformeCopList";
import InformeCopDetail from "../pages/InformesCopropietario/InformeCopDetail";
import InformeCopArchivos from "../pages/InformesCopropietario/InformeCopArchivos";
import InformeCopPresupuestos from "../pages/InformesCopropietario/InformeCopPresupuestos";
import InformeCopObras from "../pages/InformesCopropietario/InformeCopObras";
import InformeCopNotas from "../pages/InformesCopropietario/InformeCopNotas";
import InformeCopMemorias from "../pages/InformesCopropietario/InformeCopMemorias";



const InformesCopRoutes = [
    <PrivateRoute key="informe-list" exact path="/copropietario/informes/:idEdificio/lista" component={InformeCopList} />,
    <PrivateRoute key="informe-detail" exact path="/copropietario/informes/:idEdificio/lista/:informeId" component={InformeCopDetail} />,
    <PrivateRoute key="informe-archivos" path="/copropietario/informes/:idEdificio/lista/archivos/:informeId" component={InformeCopArchivos} />,
    <PrivateRoute key="informe-presupuestos" path="/copropietario/informes/:idEdificio/lista/presupuestos/:informeId" component={InformeCopPresupuestos} />,
    <PrivateRoute key="informe-obras" path="/copropietario/informes/:idEdificio/lista/obras/:informeId" component={InformeCopObras} />,
    <PrivateRoute key="informe-notas" path="/copropietario/informes/:idEdificio/lista/notas/:informeId" component={InformeCopNotas} />,
    <PrivateRoute key="informe-memorias" path="/copropietario/informes/:idEdificio/lista/memorias/:informeId" component={InformeCopMemorias} />,
];

export default InformesCopRoutes;
