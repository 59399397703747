import axios from "axios";

const baseURL = process.env.NODE_ENV === "development" 
  ? process.env.REACT_APP_BASE_URL_DEVELOPMENT 
  : process.env.REACT_APP_BASE_URL_PRODUCTION;

const axiosInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true, 
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json, text/plain, */*"
  }
});

const axiosMultipartInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true, 
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept": "application/json, text/plain, */*"
  }
});

export { axiosInstance, axiosMultipartInstance };
