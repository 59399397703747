import { useState } from 'react';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';

const useCamera = () => {
    const [error, setError] = useState<string | null>(null);

    const takePhoto = async (): Promise<Photo | null> => {
        try {
            // Llamamos a la cámara para capturar una foto
            const photo = await Camera.getPhoto({
                resultType: CameraResultType.Uri, // Devuelve la foto como una URL de datos
                source: CameraSource.Camera,         // Usamos la cámara como fuente
                quality: 90,                         // Ajustamos la calidad
            });

            // Retornamos la foto capturada
            return photo;
        } catch (err) {
            // Si ocurre un error, lo manejamos
            setError("Error al tomar la foto. Intenta nuevamente.");
            console.error(err);

            // Retornamos null para indicar fallo
            return null;
        }
    };

    return { takePhoto, error };
};

export default useCamera;

