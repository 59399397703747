import {
    IonCard,
    IonCardContent,
    isPlatform
} from "@ionic/react";
import "./ArchivoCard.css";


import "moment/locale/es";
import { FaFilePdf } from "react-icons/fa6";

type ArchivoCardProps = {
    src?: string;
    alt?: string;
    nombre?: string;
    pdfUrl?: string

};
const openPdf = (url: string | undefined) => {
    if (isPlatform("mobile")) {
        // Platform is Android or iOS
        window.open(url, "_system");
       
    } else {
        // Platform is web
        window.open(url, "_blank");
    }
};
const ArchivoCard: React.FC<ArchivoCardProps> = ({
    pdfUrl,
    src,
    alt,
    nombre
}) => {
    return (
        <IonCard
            className="ion-card-edificio"
            onClick={() =>
                openPdf(
                    pdfUrl
                )
            }
        >

            <IonCardContent>
                <div className="d-flex align-items-center card-header-archivo">
                    <span className="spn-icon-archivo"><FaFilePdf className="svg-icon-archivo" /></span>
                    <span className="spn-txt-archivo">
                        <h5 className="font-dark-grey txt-header-archivo">
                            {nombre}
                        </h5>
                    </span>
                </div>
                <div className="img-box-archivo">
                    <img alt={alt} src={src} className="img-card-archivo" />
                </div>

            </IonCardContent>

        </IonCard>
    );
}
export default ArchivoCard;