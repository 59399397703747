import React, { useState } from "react";
import {
    IonCard,
    IonCardContent,
    IonModal,
    IonButton,
    IonIcon
} from "@ionic/react";
import moment from "moment";
import "moment/locale/es";
import { IReporte } from "../../../store/ducks/reportes/types";
import { closeOutline, searchOutline } from "ionicons/icons";
import "./ReporteDetailCard.css";

export type ReporteDetailProps = {
    reporte?: IReporte;
};

const ReporteDetailCard: React.FC<ReporteDetailProps> = ({ reporte }) => {
    const [showImageModal, setShowImageModal] = useState(false);

    const handleImageClick = () => setShowImageModal(true);
    const closeImageModal = () => setShowImageModal(false);

    return (
        <>
            
            {/* Card de Información Adicional */}
            <IonCard className="ion-card-descripcion">
                <IonCardContent className="ion-card-descripcion-content">
                    {reporte?.sector && (
                        <div>
                            <h4 className="font-dark-grey mb-1 font-weight-500">Sector</h4>
                            <p>{reporte.sector}</p>
                        </div>
                    )}

                    {reporte?.tipoDesperfecto && (
                        <div className="mt-2">
                            <h4 className="font-dark-grey mb-1 font-weight-500">Categoría</h4>
                            <p>{reporte.tipoDesperfecto}</p>
                        </div>
                    )}


                    {reporte?.prioridad && (
                        <div className="mt-2">
                            <h4 className="font-dark-grey mb-1 font-weight-500">Prioridad</h4>
                            <p className="text-capitalize">{reporte.prioridad}</p>
                        </div>
                    )}

                    {reporte?.estado && (
                        <div className="mt-2">
                            <h4 className="font-dark-grey mb-1 font-weight-500">Estado</h4>
                            <p className="text-capitalize">{reporte.estado}</p>
                        </div>
                    )}

                    {reporte?.evaluacionTecnica !== undefined && (
                        <div className="mt-2">
                            <h4 className="font-dark-grey mb-1 font-weight-500">Evaluación Técnica</h4>
                            <p>{reporte.evaluacionTecnica ? "Sí" : "No solicitada"}</p>
                        </div>
                    )}

                    {reporte?.updatedAt && (
                        <div className="mt-2">
                            <h4 className="font-dark-grey mb-1 font-weight-500">Última actualización</h4>
                            <p>{moment(reporte.updatedAt).locale("es").format("LL")}</p>
                        </div>
                    )}
                </IonCardContent>
            </IonCard>

            {reporte?.imgUrl && (
                <IonModal isOpen={showImageModal} onDidDismiss={closeImageModal} className="img-custom-modal">
                    <div className="image-modal-container">
                        <IonButton className="close-image-button" fill="clear" onClick={closeImageModal}>
                            <IonIcon icon={closeOutline} size="large" />
                        </IonButton>
                        <img
                            src={reporte.imgUrl}
                            alt="Imagen del reporte en pantalla completa"
                            className="modal-img"
                        />
                    </div>
                </IonModal>
            )}
        </>
    );
};

export default React.memo(ReporteDetailCard);
