import { axiosInstance } from "./axiosConfig";

export const requestGetInformesByTecnico = () =>
  axiosInstance.get(`/api/informes/getByTecnico`);

export const requestGetInformesByEdificio = (edificioId: any) =>
  axiosInstance.get(`/api/informes/getByEdificio/${edificioId}`);

export const requestGetInformeById = (informeId: any) =>
  axiosInstance.get(`/api/informes/${informeId}`);
