import PrivateRoute from "./PrivateRoute";

import MemoriasCopList from "../pages/MemoriasCopropietario/MemoriasCopList";



const MemoriasCopRoutes = [
    <PrivateRoute key="memoria-list" exact path="/copropietario/memorias/:idEdificio/lista" component={MemoriasCopList} />,
];

export default MemoriasCopRoutes;
