import { call, put, takeLatest } from "redux-saga/effects";
import {
  requestPostAvisos,
  requestGetAvisosByEdificio,
  requestDeleteAvisoById,
} from "../../../api/avisos"; // Ajustar path según tu estructura
import {
  postAvisoRequest,
  postAvisoSuccess,
  postAvisoFail,
  getAvisosByEdificio,
  setAvisosByEdificio,
  setAvisosByEdificioFail,
  deleteAvisoRequest,
  deleteAvisoSuccess,
  deleteAvisoFail

} from "./index"; // Importa desde el slice

function* postAvisoSaga(action: ReturnType<typeof postAvisoRequest>) {
  try {
    const { aviso, idEdificio } = action.payload;
    const { data } = yield call(requestPostAvisos, idEdificio, aviso);

    yield put(postAvisoSuccess({ aviso: data.aviso, message: data.message }));
  } catch (error: any) {
    let errorMessage = "Ha ocurrido un error inesperado";
    if (error.response?.data?.message) {
      errorMessage = error.response.data.message;
    }
    yield put(postAvisoFail({ message: errorMessage }));
  }
}

function* getAvisosByEdificioSaga(action: ReturnType<typeof getAvisosByEdificio>) {
  try {
    const { idEdificio } = action.payload;
    const { data } = yield call(requestGetAvisosByEdificio, idEdificio);

    yield put(setAvisosByEdificio({ avisos: data.avisos, message: data.message }));
  } catch (error: any) {
    let errorMessage = "Ha ocurrido un error al obtener los avisos";
    if (error.response?.data?.message) {
      errorMessage = error.response.data.message;
    }
    yield put(setAvisosByEdificioFail({ message: errorMessage }));
  }
}

function* handleDeleteAviso(action: ReturnType<typeof deleteAvisoRequest>) {
  try {
    const {data} = yield call(requestDeleteAvisoById, action.payload.id);
    
    yield put(
      deleteAvisoSuccess({
        id: action.payload.id,
        message: data.message,
        scope: action.payload.scope,
      })
    );
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 404) {
        yield put(deleteAvisoFail({ message: data.message, scope: action.payload.scope }));
      } else if (status === 403) {
        yield put(deleteAvisoFail({ message: data.message, scope: action.payload.scope }));
      } else if (status === 500) {
        // yield put(setCriticalError({ message: data.message })); // Acción futura para errores críticos
      } else {
        yield put(deleteAvisoFail({ message: "Error inesperado", scope: action.payload.scope }));
      }
    } else {
      yield put(deleteAvisoFail({ message: "Error de conexión", scope: action.payload.scope }));
    }
  }
}

export function* avisosWatcherPostAviso() {
  yield takeLatest(postAvisoRequest.type, postAvisoSaga);
}

export function* avisosWatcherGetAvisosByEdificio() {
  yield takeLatest(getAvisosByEdificio.type, getAvisosByEdificioSaga);
}

export function* avisosWatcherDeleteAvisoById() {
  yield takeLatest(deleteAvisoRequest.type, handleDeleteAviso);
}