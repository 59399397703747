import React from "react";
import { IonCard, IonCardContent } from "@ionic/react";
import moment from "moment";
import "moment/locale/es";
import "./TitleCard.css";
import { FiCalendar } from "react-icons/fi";

export type TitleCardProps = {
    titulo?: string;
    fecha?: Date;
};

const TitleCard: React.FC<TitleCardProps> = ({ titulo, fecha }) => {
    return (
        <IonCard className="ion-card-descripcion">
            <IonCardContent className="ion-card-descripcion-content">
                {titulo && (
                    <h4 className="font-dark-grey font-weight-500 text-capitalize card-detail-title">
                        {titulo}
                    </h4>
                )}
                {fecha && (
                    <div className="mt-2 d-flex align-items-center text-small">
                        <span>
                            <FiCalendar className="mr-1" />
                            {moment(fecha).locale("es").format("LL")}
                        </span>
                    </div>
                )}
            </IonCardContent>
        </IonCard>
    );
};

export default TitleCard;
