import { fork, all } from "redux-saga/effects";
import {
  userWatcherLoginRequest,
  userWatcherLogout,
  userWatcherGetUserById,
  userWatcherGetUserProfile,
  userWatcherGetUserEdificios,
  userWatcherCheckAuthStatus
} from "./users/sagas";
import {
  edificiosWatcherGetEdificios,
  edificiosWatcherPostEdificio,
  edificiosWatcherGetEdificioById,
  edificiosWatcherGetEdificiosUnread,
  edificiosWatcherGetEdificioCopropietario,
  edificiosWatcherGetEdificioInformes
} from "./edificios/sagas";
import { obrasWatcherGetObrasByEdificio, obrasWatcherGetObraById } from "./obras/sagas"
import { informesWatcherGetInformesByTecnico, informesWatcherGetInformesByEdificio, informesWatcherGetInformeById } from "./informes/sagas";
import { visitasWatcherGetVisitasByObra, visitasWatcherGetVisitaById } from "./visitas/sagas";
import { observacionesWatcherGetObservacionesByVisita } from "./observaciones/sagas";
import { reportesWatcherGetReportesByEdificio, reportesWatcherPostReporte, reportesWatcherGetReporteById, reportesWatcherdeleteReporteById } from "./reportes/sagas"
import { avisosWatcherGetAvisosByEdificio, avisosWatcherPostAviso, avisosWatcherDeleteAvisoById  } from "./avisos/sagas"

function* rootSaga() {
  yield all([
    fork(userWatcherLoginRequest),
    fork(userWatcherCheckAuthStatus),
    fork(userWatcherLogout),
    fork(userWatcherGetUserProfile),
    fork(userWatcherGetUserEdificios),
    fork(userWatcherGetUserById),
    fork(edificiosWatcherGetEdificios),
    fork(edificiosWatcherPostEdificio),
    fork(edificiosWatcherGetEdificioById),
    fork(edificiosWatcherGetEdificiosUnread),
    fork(edificiosWatcherGetEdificioInformes),
    fork(edificiosWatcherGetEdificioCopropietario),
    fork(obrasWatcherGetObrasByEdificio),
    fork(obrasWatcherGetObraById),
    fork(visitasWatcherGetVisitasByObra),
    fork(visitasWatcherGetVisitaById),
    fork(observacionesWatcherGetObservacionesByVisita),
    fork(informesWatcherGetInformesByTecnico),
    fork(informesWatcherGetInformesByEdificio),
    fork(informesWatcherGetInformeById),
    fork(reportesWatcherPostReporte),
    fork(reportesWatcherGetReportesByEdificio),
    fork(reportesWatcherGetReporteById),
    fork(reportesWatcherdeleteReporteById),
    fork(avisosWatcherGetAvisosByEdificio),
    fork(avisosWatcherPostAviso),
    fork(avisosWatcherDeleteAvisoById)
  ]);
}

export { rootSaga };
