import {
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonToast,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import "../../styles/App.css";
import ToolbarMenu from "../../components/Toolbars/ToolbarMenu";
import { getAvisosByEdificio, clearMessage, deleteAvisoRequest } from "../../store/ducks/avisos/index";
import { RouteComponentProps } from "react-router";
import { add } from "ionicons/icons";
import { AvisosScopes } from "../../utils/avisosScope";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import { IAviso } from "../../store/ducks/avisos/types";
import AvisoListItem from "../../components/Copropietario/Avisos/AvisoListItem";

type Params = { idEdificio: string };

const AvisosCopList: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { idEdificio },
  },
}) => {
  const { avisosByEdificio, errorMessage, scope, avisosLoading } = useSelector((state: RootState) => state.avisos);
   const [isOpen, setIsOpen] = useState(false);
   
  const dispatch = useDispatch();

  useIonViewWillEnter(() => {
    dispatch(getAvisosByEdificio({idEdificio}));
    dispatch(clearMessage());
  });

  useIonViewWillLeave(() => {
    dispatch(clearMessage());
  });

   useEffect(() => {
          // Si tenemos un error y el scope corresponde, abrimos el Toast
          if (errorMessage !== "" && scope === AvisosScopes.LISTA_AVISOS) {
              setIsOpen(true);
          }
      }, [errorMessage, scope]);

  const handleEdit = (id: string) => {
    console.log("Editar aviso con ID:", id);

  };

  const handleDelete = (id: string) => {
    dispatch(deleteAvisoRequest({id, scope: AvisosScopes.LISTA_AVISOS} ))
  };
  
  return (
    <IonPage>
      <IonToast
        isOpen={isOpen}
        message={errorMessage}
        layout="stacked"
        buttons={[
          {
            text: 'Cerrar',
            role: 'cancel',
            handler: () => {
              setIsOpen(false);
              dispatch(clearMessage())
            },
          },
        ]}
        position='middle'
      />
      <IonHeader className="ion-no-border border-header">
        <ToolbarMenu title="Avisos" />
      </IonHeader>
      <IonContent fullscreen className="ion-content-grey">
        {(avisosLoading) ? (
          <div className="center-content">
            <CustomSpinner color="primary" />
          </div>

        ) : (
          <>
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton
                routerLink={`/copropietario/tabs/${idEdificio}/nuevo/avisos`}
                className="fab-button-reportes"
              >
                <IonIcon icon={add} className="ion-add-icon" />
              </IonFabButton>
            </IonFab>
            <IonGrid className="pb-50">
              <IonRow className="dashboard-row">

                {avisosByEdificio.length > 0 ? (
                  avisosByEdificio.map((aviso: IAviso) => (
                    <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6" key={aviso._id}>
                      <AvisoListItem
                        link={`/copropietario/tabs/${idEdificio}/avisos/${aviso._id}`}
                        imgUrl={aviso.imgUrl || "#"}
                        alt="aviso"
                        aviso={aviso}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                      />
                    </IonCol>
                  ))
                ) : (
                  <IonCol size="12" className="center-content">
                    <p>No se encontraron avisos.</p>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </>

        )}
      </IonContent>
    </IonPage>
  );
};

export default AvisosCopList;