import { axiosInstance, axiosMultipartInstance } from "./axiosConfig";

export const requestPostAvisos = (idEdificio: any, aviso: FormData) =>
  axiosMultipartInstance.post(`/api/avisos/${idEdificio}`, aviso);

export const requestGetAvisosByEdificio = (idEdificio: any) =>
  axiosInstance.get(`/api/avisos/${idEdificio}`);

export const requestDeleteAvisoById = (idAviso: any) =>
  axiosInstance.patch(`/api/avisos/aviso/${idAviso}`);
