import { axiosInstance, axiosMultipartInstance } from "./axiosConfig";

export const requestPostReporte = (idEdificio: any, reporte: FormData) =>
  axiosMultipartInstance.post(`/api/reportes-desperfectos/${idEdificio}`, reporte);

export const requestGetReportesByEdificio = (idEdificio: any) =>
  axiosInstance.get(`/api/reportes-desperfectos/${idEdificio}`);

export const requestGetReporteById = (idReporte: any) =>
  axiosInstance.get(`/api/reportes-desperfectos/reporte/${idReporte}`);

export const requestDeleteReporteById = (idReporte: any) =>
  axiosInstance.patch(`/api/reportes-desperfectos/reporte/${idReporte}`);
