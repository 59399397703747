import ReactDOM from "react-dom/client";
import App from "./App";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider, useDispatch } from "react-redux";
import configureStore from "./store";
import { isPlatform } from "@ionic/react";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import { setNewContentAvailable } from "./store/ducks/users/actions";

const { store } = configureStore();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);


root.render(<Provider store={store}><App /></Provider>);

defineCustomElements(window);

// Al registrar el Service Worker

if (process.env.NODE_ENV === 'development') {
    serviceWorkerRegistration.unregister();
} else {
   
    serviceWorkerRegistration.register({
        onUpdate: () => {
          
            store.dispatch(setNewContentAvailable());
        }
    });
} 