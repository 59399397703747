import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewWillEnter,
  } from '@ionic/react';
  import "../../styles/App.css";
  import { useSelector } from 'react-redux';
  import { RootState } from '../../store/ducks/rootReducer';
  import React from 'react';
  import { StatusBar, Style } from '@capacitor/status-bar';
  import ToolbarMenu from '../../components/Toolbars/ToolbarMenu';
import { RouteComponentProps } from 'react-router';
import ToolbarBack from '../../components/Toolbars/ToolbarBack';
  
 type Params = { idEdificio: string };
 
 const ServiciosCopList: React.FC<RouteComponentProps<Params>> = ({
     match: {
         params: { idEdificio },
     },
 }) => {
    const { _id } = useSelector((state: RootState) => state.users);
    return (
      <IonPage>
        <IonHeader className="ion-no-border border-header">
          <ToolbarBack title="Servicios" backLink={`/copropietario/tabs/${idEdificio}/escritorio`}  />
        </IonHeader>
  
        <IonContent fullscreen className="ion-content-grey">
          <IonGrid></IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  
  export default ServiciosCopList;