import {
  call,
  put,
  takeLatest,
} from "redux-saga/effects";
import { requestGetEdificioById, requestGetEdificios, requestGetEdificioObras, requestGetEdificiosUnreaded, requestPostEdificio, requestGetEdificioCopropietario } from "../../../api/edificios";
import {
  setEdificios,
  setEdificioById,
  postEdificioSuccess,
  postEdificioFail,
  setEdificioErrorMessage,
  setEdificiosUnread,
  setEdificioCopropietario,
  setEdificioInfomes
} from "./actions";
import { EdificioTypes, IEdificio, PostEdificioRequestAction, GetEdificioByIdAction, GetEdificioInfomesAction} from "./types";
import { requestGetInformesByEdificio } from "../../../api/informes";
import { IInforme } from "../informes/types";
import { setInformesByEdificio } from "../informes/actions";


function* getEdificiosRequest() {
  try {
    const { data } = yield call(requestGetEdificios);
   
    if (data.edificios) {
      const edificiosResult: [IEdificio] = data.edificios;
      yield put(setEdificios(edificiosResult));
    }
  } catch (error: any) {
    if (error.response) {
      console.log(error)
    }
  }
}

function* getEdificioInformesRequest({ payload }: GetEdificioInfomesAction) {
  try {
    const _id = payload.id;
    const { data } = yield call(requestGetInformesByEdificio, _id);
    if (data.informes) {
      const informesResult: [IInforme] = data.informes;
      yield put(setEdificioInfomes(informesResult));
    }
  } catch (error: any) {
    if (error.response) {
      console.log(error)
    }
  }
}

function* getEdificioCopropietarioRequest() {
  try {
    
    const { data, status } = yield call(requestGetEdificioCopropietario);
    if (status === 200 && data.edificio) {
      const edificioResult: IEdificio = data.edificio;
      yield put(setEdificioCopropietario(edificioResult));
    } else if (status === 304) {
      // Si el servidor responde con un estado 304, no hacer nada o usar los datos almacenados en Redux.
      // Si es necesario, podrías hacer algo como loggear que los datos no han cambiado.
      console.log('El edificio no ha cambiado, utilizando datos existentes.');
    }
  } catch (error: any) {
    if (error.response) {
      console.log(error);
    }
  }
}


function* getEdificioUnreadRequest() {
  try {
    const { data } = yield call(requestGetEdificiosUnreaded);
    const edificiosResult: [IEdificio] = data ;
    yield put(setEdificiosUnread(edificiosResult));
  } catch (error: any) {
    if (error.response) {
      console.log(error)
    }
  }
}

function* postEdificio(action: PostEdificioRequestAction) {
  try {
    const edificio: FormData = action.payload.edificio;
    const { data } = yield call(requestPostEdificio, edificio);
    const edificiosResult: IEdificio = data.edificioSaved;
    const message : string = data.message;
    yield put(postEdificioSuccess(edificiosResult, message));
  } catch (error: any) {
    if (error.response) {
      const { data } = error.response;
      console.log(data);
      yield put(postEdificioFail(data.message));
    }
  }
}

function* getEdificioByIdRequest({ payload }: GetEdificioByIdAction) {
  try {
    const _id = payload.id;
    const { data } = yield call(requestGetEdificioById, _id);
    const edificioResult: IEdificio = data ;
    yield put(setEdificioById(edificioResult));
  } catch (error: any) {
    if (error.response) {
      console.log(error);
      console.log(error.response);
      const { data } = error.response;
      yield put(setEdificioErrorMessage(data.message))
    }
  }
}
/* function* postEdificio(action, token) {
  try {
    token = getToken();
    const { edificio } = action.payload;

    const resp = yield call(requestPostEdificio, edificio, token);
    const { data } = resp;
    yield put(
      edificiosActions.postEdificioSuccess(data.edificioSaved, data.message)
    );
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      console.log(data.message);
      yield put(edificiosActions.setErrorMessage(data));
    }
  }
} */
export function* edificiosWatcherGetEdificios() {
  yield takeLatest(EdificioTypes.GET_EDIFICIOS, getEdificiosRequest);
}
export function* edificiosWatcherGetEdificioInformes() {
  yield takeLatest(EdificioTypes.GET_EDIFICIO_INFORMES, getEdificioInformesRequest);
}
export function* edificiosWatcherGetEdificiosUnread() {
  yield takeLatest(EdificioTypes.GET_EDIFICIOS_UNREAD, getEdificioUnreadRequest);
}

export function* edificiosWatcherGetEdificioById() {
  yield takeLatest(EdificioTypes.GET_EDIFICIO_BY_ID, getEdificioByIdRequest);
}

export function* edificiosWatcherGetEdificioCopropietario() {
  yield takeLatest(EdificioTypes.GET_EDIFICIO_COPROPIETARIO, getEdificioCopropietarioRequest);
}

 export function* edificiosWatcherPostEdificio() {
  yield takeLatest(EdificioTypes.POST_EDIFICIO_REQUEST, postEdificio);
} 

