import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AvisoState, IAviso } from "./types";
import { AvisosScopes } from "../../../utils/avisosScope";

const initialState: AvisoState = {
  inPromise: false,
  avisoByIdLoad: false,
  avisosLoading: false,
  avisosLoad: false,
  avisoById: null,
  avisoNuevoLoading: false,
  avisoNuevo: null,
  avisosByEdificio: [],
  avisosUnread: [],
  message: "",
  errorMessage: "",
  scope: null,
};

const avisoSlice = createSlice({
  name: "avisos",
  initialState,
  reducers: {
    // POST AVISO
    postAvisoRequest: (
      state,
      action: PayloadAction<{ aviso: FormData; idEdificio: any }>
    ) => {
      state.inPromise = true;
      state.errorMessage = "";
      state.avisoNuevoLoading = true;
      state.scope = AvisosScopes.CREAR_AVISO;
      state.message = "";
    },

    postAvisoSuccess: (
      state,
      action: PayloadAction<{ aviso: IAviso; message: string }>
    ) => {
      state.inPromise = false;
      state.avisoNuevo = action.payload.aviso;
      state.avisoNuevoLoading = false;
      state.message = action.payload.message;
      state.scope = AvisosScopes.CREAR_AVISO;
      state.errorMessage = "";
    },

    postAvisoFail: (state, action: PayloadAction<{ message: string }>) => {
      state.inPromise = false;
      state.avisoNuevoLoading = false;
      state.message = "";
      state.scope = AvisosScopes.CREAR_AVISO;
      state.errorMessage = action.payload.message;
    },

    // GET AVISOS BY EDIFICIO
    getAvisosByEdificio: (
      state,
      action: PayloadAction<{ idEdificio: string }>
    ) => {
      state.inPromise = true;
      state.avisosLoad = false;
      state.message = "";
      state.avisosLoading = true;
      state.scope = AvisosScopes.LISTA_AVISOS;
      state.errorMessage = "";
    },

    setAvisosByEdificio: (
      state,
      action: PayloadAction<{ avisos: IAviso[]; message: string }>
    ) => {
      state.inPromise = false;
      state.avisosByEdificio = action.payload.avisos;
      state.avisosLoad = true;
      state.message = action.payload.message;
      state.avisosLoading = false;
      state.scope = AvisosScopes.LISTA_AVISOS;
      state.errorMessage = "";
    },
    // DELETE AVISO
    deleteAvisoRequest: (
      state,
      action: PayloadAction<{ id: string; scope: string }>
    ) => {
      state.errorMessage = "";
      state.message = "";
      state.scope = action.payload.scope;
    },

    deleteAvisoSuccess: (
      state,
      action: PayloadAction<{ id: string; message: string; scope: string }>
    ) => {
      if (action.payload.scope === AvisosScopes.DETALLE_AVISO) {
        state.avisoById = null;
      } else if (action.payload.scope === AvisosScopes.LISTA_AVISOS) {
        state.avisosByEdificio = state.avisosByEdificio.filter(
          (aviso) => aviso._id !== action.payload.id
        );
      }
      state.message = action.payload.message;
      state.errorMessage = "";
    },

    deleteAvisoFail: (
      state,
      action: PayloadAction<{ message: string; scope: string }>
    ) => {
      state.errorMessage = action.payload.message;
      state.message = "";
      state.scope = action.payload.scope;
    },
    setAvisosByEdificioFail: (state, action: PayloadAction<{ message: string }>) => {
      state.inPromise = false;
      state.message = "";
      state.errorMessage = action.payload.message;
      state.avisosLoading = false;
      state.scope = AvisosScopes.LISTA_AVISOS;
    },

    clearMessage: (state) => {
      state.message = "";
      state.errorMessage = "";
    },
  },
});

export const {
  postAvisoRequest,
  postAvisoSuccess,
  postAvisoFail,
  getAvisosByEdificio,
  setAvisosByEdificio,
  setAvisosByEdificioFail,
  deleteAvisoFail,
  deleteAvisoRequest,
  deleteAvisoSuccess,
  clearMessage,
} = avisoSlice.actions;

export default avisoSlice.reducer;