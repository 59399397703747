import React from "react";
import {
    IonCard,
    IonCardContent
} from "@ionic/react";


import "./InformeListItem.css";
import { FiCalendar } from "react-icons/fi";

import moment from "moment";
import "moment/locale/es";
import { IInforme } from "../../../store/ducks/informes/types";

type InformeListItemProps = {
    link: string;
    src: string;
    alt: string;
    informe: IInforme;
};

const InformeListItem: React.FC<InformeListItemProps> = ({
    link,
    src,
    alt,
    informe: { createdAt, titulo, tecnico, tipo },

}) => {
    
    return (
        <IonCard
            routerLink={link}
            routerDirection="forward"
            className="ion-card-list"
        >
            <div className="img-card-box">
                <img alt={alt} src={src} className="img-card-list" />
            </div>
            <IonCardContent className="ion-card-list-content">
                <div className="mb-2 d-flex align-items-center text-small">
                    <span>
                        <FiCalendar className="mr-1" />
                        {moment(createdAt).locale("es").format("LL")}
                    </span>
                </div>
                <h4 className="mb-2 font-16 font-dark-grey font-weight-500 txt-titulo-list-item">
                    {`${titulo}`}
                </h4>
                <div className="mb-1 justify-content-between d-flex">
                    <p className="text-capitalize font-14">
                        {`Tecnico `}
                    </p>
                    <p className="text-capitalize font-14">
                        {`Arq. ${tecnico?.nombre} ${tecnico?.apellido}`}
                    </p>
                </div>
                <div className="mb-1 justify-content-between d-flex">
                    <p className="text-capitalize font-14">
                        {`Tipo `}
                    </p>
                    <p className="text-capitalize font-14">
                        {`${tipo}`}
                    </p>
                </div>
            </IonCardContent>
        </IonCard>
    );
}
export default React.memo(InformeListItem);