import {
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonMenuButton,
    IonProgressBar
  } from "@ionic/react";
  import { Redirect, Route } from "react-router";
  
  import {
    business,
    calendar,
    listOutline,
    logOutOutline,
    personOutline,
    mailOutline,
    analyticsOutline,
    businessOutline,
  } from "ionicons/icons";
  
  import { StatusBar, Style } from "@capacitor/status-bar";
  import { useEffect } from "react";
  import "./Toolbar.css";
  import { ReactElement } from "react";
  
  export type ToolbarProps = {
    title: string
  };
  export default function ToolbarMenu({
    title
  }: ToolbarProps): ReactElement {
    return (
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle className="font-size-xl">{title}</IonTitle>
        
        </IonToolbar>
    );
  }