import { useEffect } from "react";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import { isPlatform } from "@ionic/react";

// Verifica si el plugin StatusBar está disponible
const isStatusBarAvailable = () => Capacitor.isPluginAvailable("StatusBar");

// Obtiene el valor de una variable CSS de Ionic
const getCssVariable = (variableName: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
};

// Hook para manejar la StatusBar dinámicamente con cambio de tema automático y `overlay: false`
const useStatusBar = () => {
  useEffect(() => {
    if (!isStatusBarAvailable() || !(isPlatform("android") || isPlatform("ios"))) return;

    const applyStatusBarSettings = async () => {
      try {
        const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
        
        // Obtener el color y el estilo desde CSS
        const statusBarColor = getCssVariable("--color-background-body") || (prefersDark ? "#121318" : "#edf0f5");
        const statusBarStyle = getCssVariable("--statusbar-style") || (prefersDark ? "LIGHT" : "DARK");

        // Aplicar el color de fondo de la StatusBar
        await StatusBar.setBackgroundColor({ color: statusBarColor });

        // Evitar que la StatusBar se superponga a la WebView (solo en Android)
        if (isPlatform("android")) {
          await StatusBar.setOverlaysWebView({ overlay: false });
        }

        // Aplicar el estilo directamente usando un mapa de conversión
        await StatusBar.setStyle({
          style: Style[statusBarStyle.trim().toUpperCase() as keyof typeof Style] || Style.Light,
        });

        console.log("StatusBar actualizada:", { statusBarColor, statusBarStyle });

      } catch (error) {
        console.error("Error al configurar StatusBar:", error);
      }
    };

    // Aplicar ajustes iniciales
    applyStatusBarSettings();

    // Escuchar cambios en el modo oscuro del sistema
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", applyStatusBarSettings);

    // Observar cambios en el `body.classList` (cuando la app cambia de tema dinámicamente)
    const observer = new MutationObserver(() => applyStatusBarSettings());
    observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

    return () => {
      mediaQuery.removeEventListener("change", applyStatusBarSettings);
      observer.disconnect();
    };
  }, []);
};

export default useStatusBar;
