import React, { useState } from "react";
import { IonCard, IonCardContent, IonModal, IonButton, IonIcon } from "@ionic/react";
import { closeOutline, searchOutline } from "ionicons/icons";
import "./CardImage.css";

export type ReporteImagenProps = {
    imgUrl?: string;
};

const ReporteImagen: React.FC<ReporteImagenProps> = ({ imgUrl }) => {
    const [showImageModal, setShowImageModal] = useState(false);

    if (!imgUrl) return null;

    return (
        <>
            <IonCard className="ion-card-descripcion-img">
               
                    <div className="container-img-with-icon">
                        <img src={imgUrl} alt="Imagen del reporte" className="reporte-img" onClick={() => setShowImageModal(true)} />
                        <IonIcon icon={searchOutline} className="reporte-img-lupa" />
                    </div>
                
            </IonCard>

            {/* Modal de imagen ampliada */}
            <IonModal isOpen={showImageModal} onDidDismiss={() => setShowImageModal(false)} className="img-custom-modal">
                <div className="image-modal-container">
                    <IonButton className="close-image-button" fill="clear" onClick={() => setShowImageModal(false)}>
                        <IonIcon icon={closeOutline} size="large" />
                    </IonButton>
                    <img src={imgUrl} alt="Imagen del reporte en pantalla completa" className="modal-img" />
                </div>
            </IonModal>
        </>
    );
};

export default ReporteImagen;
