import React, { useEffect } from "react";

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  RefresherEventDetail
} from "@ionic/react";

import "./ObrasCopList.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";

import { RouteComponentProps } from "react-router";

import ToolbarMenu from "../../components/Toolbars/ToolbarMenu";
import { getObrasByEdificio } from "../../store/ducks/obras/actions";
import { IObra } from "../../store/ducks/obras/types";

import CustomSpinner from "../../components/Spinner/CustomSpinner";
import ObraListItem from "../../components/Copropietario/Obras/ObraListItem";

type Params = { idEdificio: string };

const ObrasCopList: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { idEdificio },
  },
}) => {

  const { obras, obrasLoad } = useSelector(
    (state: RootState) => state.obras
  );


  const dispatch = useDispatch();


  useEffect(() => {

    if (!obrasLoad) {
      dispatch(getObrasByEdificio(idEdificio));
    }

  }, [obrasLoad]);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      if (idEdificio) {
        dispatch(getObrasByEdificio(idEdificio));
      }
      event.detail.complete();
    }, 2000);
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border border-header">

        <ToolbarMenu title="Obras" />

      </IonHeader>
      {(!obrasLoad) ? (
        <IonContent fullscreen className="ion-content-grey">
          <div className="center-content">
            <CustomSpinner color="primary" />
          </div>
        </IonContent>
      ) : (
        <IonContent fullscreen className="ion-content-grey">

          <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>

          <IonGrid className="pb-50">
            <IonRow className="dashboard-row">

              {obras.length > 0 ? (
                obras.map((obra: IObra) => (
                  <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6" key={obra._id}>
                    <ObraListItem
                      link={`/copropietario/tabs/${idEdificio}/obras/${obra._id}`}
                      src={obra.imgUrl || "#"}
                      alt="obra"
                      obra={obra}
                    />
                  </IonCol>
                ))
              ) : (
                <IonCol size="12" className="center-content">
                  <p>No se encontraron obras.</p>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>

        </IonContent>)}
    </IonPage>
  );
};

export default ObrasCopList;

