import { axiosInstance, axiosMultipartInstance } from "./axiosConfig";

export const requestGetEdificios = () =>
  axiosInstance.get("/api/edificios");

export const requestGetEdificioCopropietario = () =>
  axiosInstance.get("/api/edificios/copropietario");

export const requestGetEdificiosUnreaded = () =>
  axiosInstance.get("/api/edificios/nuevos");

export const requestGetEdificioById = (idEdificio: any) =>
  axiosInstance.get(`/api/edificios/${idEdificio}`);

export const requestGetEdificioObras = (idEdificio: any) =>
  axiosInstance.get(`/api/edificios/${idEdificio}/obras`);

export const requestPostEdificio = (edificio: FormData) =>
  axiosMultipartInstance.post(`/api/edificios`, edificio);

export const requestPostEdificioObra = (idEdificio: any, obra: any) =>
  axiosInstance.post(`/api/edificios/${idEdificio}/obras`, obra);
