import React from "react";
import { IonCard, IonCardContent } from "@ionic/react";
import "./DescriptionCard.css";

export type DescripcionCardProps = {
    descripcion?: string;
};

const DescriptionCard: React.FC<DescripcionCardProps> = ({ descripcion }) => {
    if (!descripcion) return null;

    return (
        <IonCard className="ion-card-descripcion">
            <IonCardContent className="ion-card-descripcion-content">
                <div>
                    <h4 className="font-dark-grey mb-1 font-weight-500">Descripción</h4>
                    <p>{descripcion}</p>
                </div>
            </IonCardContent>
        </IonCard>
    );
};

export default DescriptionCard;