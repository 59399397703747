import React, { useEffect, useState } from "react";
import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonInput,
    IonTextarea,
    IonLabel,
    IonIcon,
    IonHeader,
    IonSelect,
    IonSelectOption,
    IonToast,
    useIonRouter,
    useIonViewWillEnter,
    IonModal,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonLoading,
    useIonViewWillLeave,
} from "@ionic/react";
import "../../styles/App.css";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { alertCircleOutline, close, cloudUploadOutline } from "ionicons/icons";
import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import { RouteComponentProps } from "react-router";

import { postAvisoRequest, clearMessage } from "../../store/ducks/avisos/index"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import useCamera from '../../hooks/useCamera';

import { CATEGORIAS, PRIORIDADES, TIPO_USUARIOS } from "../../utils/avisosConstants";
import { AvisosScopes } from "../../utils/avisosScope";

// Define el tipo de los datos del formulario
type FormData = {
    titulo: string;
    contenido: string;
    prioridad: string | null;
    categoria: string | null;
    tipoUsuarios: string[] | null;
    imgUrl: File | null;
};

type Params = { idEdificio: string };

const AvisosCopCreate: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { idEdificio },
    },
}) => {

    const navigation = useIonRouter();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { message, errorMessage, avisoNuevo, avisoNuevoLoading, scope } = useSelector((state: RootState) => state.avisos);
    const { takePhoto, error } = useCamera();
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // Deshabilita el botón de guardar
    const [localMessage, setLocalMessage] = useState("");
    const [localErrorMessage, setLocalErrorMessage] = useState("");
    const [toastMessage, setToastMessage] = useState(""); // Mensaje de IonToast
    const [toastOpen, setToastOpen] = useState(false);
    const [successToastOpen, setSuccessToastOpen] = useState(false);
    const [errorToastOpen, setErrorToastOpen] = useState(false);

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        setValue,
        reset,
        clearErrors,
    } = useForm<FormData>({
        defaultValues: {
            titulo: "",
            contenido: "",
            prioridad: null,
            categoria: null,
            tipoUsuarios: null,
            imgUrl: null,
        },
    });



    const customPrioridadSheetOptions = {
        header: "Prioridad",
        subHeader: "Seleccione una prioridad de atención",
    };

    const customCategoriasSheetOptions = {
        header: "Categorías",
        subHeader: "Elija la categoría que mejor describa el aviso",
    };
    const customTiposSheetOptions = {
        header: "Usuarios permitidos",
        subHeader: "Seleccione qué tipos de usuarios podrán ver este aviso",

    };
    useIonViewWillEnter(() => {
        dispatch(clearMessage());
        setLocalMessage("");
        setLocalErrorMessage("");
        setToastMessage("");
        setErrorToastOpen(false);
        setSuccessToastOpen(false);
        setToastOpen(false);
    });

    useIonViewWillLeave(() => {
        dispatch(clearMessage());
        setLocalMessage("");
        setLocalErrorMessage("");
        setToastMessage("");
        setErrorToastOpen(false);
        setSuccessToastOpen(false);
        setToastOpen(false);
    });

    useEffect(() => {
        if (message) {
            setLocalMessage(message);
        };
        if (errorMessage) {
            setLocalErrorMessage(errorMessage);
        };
    }, [message, errorMessage]);

    useEffect(() => {
        if (localMessage && scope === AvisosScopes.CREAR_AVISO) {
            setSuccessToastOpen(true);
            reset();
            setImagePreview(null);
        };
        if (localErrorMessage && scope === AvisosScopes.CREAR_AVISO) {
            setErrorToastOpen(true);
        };
        if (toastMessage !== "") {
            setToastOpen(true);
        };
    }, [localMessage, localErrorMessage, toastMessage]);
    useEffect(() => {
        if (errorMessage && scope === AvisosScopes.CREAR_AVISO) {
            setErrorToastOpen(true);
        };
    }, [errorMessage, scope]);

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        if (file.type !== "image/jpeg") {
            setToastMessage("El archivo debe ser una imagen en formato JPG.");
            setImagePreview(null);
            return;
        };
        const reader = new FileReader();
        reader.onload = () => {
            setImagePreview(reader.result as string);
            setShowModal(false);
        };
        reader.readAsDataURL(file);
        setValue("imgUrl", file);
    };

    const handleTakePhoto = async () => {
        const photo = await takePhoto();
        if (photo?.dataUrl) {
            setImagePreview(photo.dataUrl);
            setShowModal(false);
            // Crear un Blob a partir del base64
            const base64Response = await fetch(photo.dataUrl);
            const blob = await base64Response.blob();
            // Crear un archivo con el Blob y un nombre adecuado
            const file = new File([blob], "photo.jpg", { type: "image/jpeg" });
            // Actualizar el valor del formulario con el archivo
            setValue("imgUrl", file);
        }
        else {
            console.log('No se pudo capturar la foto.');
        }
    };

    const handleSuccessToastDismiss = () => {
        setLocalMessage("");
        setSuccessToastOpen(false);
        dispatch(clearMessage()); // Limpia el mensaje en Redux también
        setIsSubmitting(false);
    };

    const handleErrorToastDismiss = () => {
        setErrorToastOpen(false);
        dispatch(clearMessage()); // Limpia el mensaje en Redux también
        setIsSubmitting(false);
    };

    const handleToastDismiss = () => {
        setToastOpen(false); // Cierra el toast
    };

    const onSubmit = (data: FormData) => {
        setIsSubmitting(true);
        const formAviso = new FormData();
        formAviso.append("titulo", data.titulo);
        formAviso.append("contenido", data.contenido);
        if(data.prioridad)formAviso.append("prioridad", data.prioridad);
        if(data.categoria)formAviso.append("categoria", data.categoria);
        // Verificar qué se está enviando
        // Enviar tipoUsuarios como JSON para evitar errores
        if(data.tipoUsuarios)formAviso.append("tipoUsuarios", JSON.stringify(data.tipoUsuarios));

        if (data.imgUrl) {
            formAviso.append("imgUrl", data.imgUrl);
        };

        dispatch(postAvisoRequest({aviso: formAviso, idEdificio}));
    };

    return (
        <IonPage>

            <IonToast
                isOpen={successToastOpen}
                message={localMessage}
                layout="stacked"
                buttons={[
                    {
                        text: "Ver aviso",
                        handler: () => {
                            navigation.push(
                                `/copropietario/tabs/${idEdificio}/avisos/${avisoNuevo?._id}`,
                                "root",
                                "replace"
                            );
                        },
                    },
                    {
                        text: "Cerrar",
                        role: "cancel",
                        handler: () => setSuccessToastOpen(false),
                    },
                ]}
                onDidDismiss={handleSuccessToastDismiss}
            />
            <IonToast
                isOpen={errorToastOpen}
                message={errorMessage}
                layout="stacked"
                position='middle'
                buttons={[
                    {
                        text: "Cerrar",
                        role: "cancel",
                        handler: () => setErrorToastOpen(false),
                    },
                ]}
                onDidDismiss={handleErrorToastDismiss}
            />
            <IonToast
                isOpen={toastOpen}
                message={toastMessage}
                position='middle'
                layout="stacked"
                buttons={[
                    {
                        text: "Cerrar",
                        role: "cancel",
                        handler: () => setToastMessage(""),
                    },
                ]}
                onDidDismiss={handleToastDismiss}
            />
            {/* IonLoading para el estado de carga */}
            <IonLoading isOpen={avisoNuevoLoading && scope === AvisosScopes.CREAR_AVISO} message={"Enviando Aviso..."}/>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title={"Crear Aviso"} backLink={`/copropietario/tabs/${idEdificio}/avisos`} />
            </IonHeader>
            <IonContent fullscreen className="ion-content-grey">
                <IonGrid className="pb-50">
                    <IonRow className="dashboard-row">
                        <IonCol size="12" size-lg="6" offset-lg="3">
                            <p className="font-dark-grey font-18">
                                Por favor, complete los campos a continuación para enviar su aviso.
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="ion-margin-bottom">
                                    <div className="create-form-label-helper">
                                        <IonLabel position="stacked" className="txt-label-helper">
                                            Título
                                        </IonLabel>
                                        <small className="helper-text">Máximo 35 caracteres</small>
                                    </div>

                                    <IonInput
                                        className="create-form-input"
                                        fill="outline"
                                        placeholder="Título (opcional)"
                                        {...register("titulo", {
                                            maxLength: 35,
                                        })}
                                        aria-label="Título"
                                        maxlength={35}
                                    />
                                </div>
                                <ErrorMessage
                                    errors={errors}
                                    name="titulo"
                                    render={() => (
                                        <div className="error-form">
                                            <IonIcon icon={alertCircleOutline} slot="start" />
                                            <span>El título no debe superar los 25 caracteres</span>
                                        </div>
                                    )}
                                />
                                <div className="ion-margin-bottom">
                                    <IonLabel position="stacked" className="create-form-label">
                                        Prioridad
                                    </IonLabel>
                                    <IonSelect
                                        {...register("prioridad")}
                                        aria-label="Prioridad"
                                        cancelText="Cancelar"
                                        interfaceOptions={customPrioridadSheetOptions}
                                        placeholder="Seleccione la prioridad"
                                        labelPlacement="stacked"
                                        fill="outline"
                                        className="create-form-input"
                                    >
                                        {PRIORIDADES.map((prioridad, index) => (
                                            <IonSelectOption key={index} value={prioridad}>
                                                {prioridad}
                                            </IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </div>
                                <div className="ion-margin-bottom">
                                    <IonLabel position="stacked" className="create-form-label">
                                        Categoría
                                    </IonLabel>
                                    <IonSelect
                                        {...register("categoria")}
                                        aria-label="Categoría"
                                        cancelText="Cancelar"
                                        interfaceOptions={customCategoriasSheetOptions}
                                        placeholder="Seleccione una categoría"
                                        labelPlacement="stacked"
                                        fill="outline"
                                        className="create-form-input"
                                    >
                                        {CATEGORIAS.map((categoria, index) => (
                                            <IonSelectOption key={index} value={categoria}>
                                                {categoria}
                                            </IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </div>
                                <div className="ion-margin-bottom">
                                    <IonLabel position="stacked" className="create-form-label mb-3">
                                        Usuarios permitidos
                                    </IonLabel>
                                    <small className="helper-text">Seleccione qué tipos de usuarios podrán ver este aviso.</small>
                                    <IonSelect
                                        {...register("tipoUsuarios")}
                                        aria-label="Usuarios permitidos"
                                        cancelText="Cancelar"
                                        interfaceOptions={customTiposSheetOptions}
                                        placeholder="Seleccione los tipos de usuarios"
                                        labelPlacement="stacked"
                                        fill="outline"
                                        className="create-form-input"
                                        multiple={true}
                                    >
                                        {TIPO_USUARIOS.map((tipo, index) => (
                                            <IonSelectOption key={index} value={tipo}>
                                                {tipo}
                                            </IonSelectOption>
                                        ))}
                                    </IonSelect>
                                </div>
                                <div className="ion-margin-bottom">
                                    <IonLabel position="stacked" className="create-form-label">
                                        Aviso <span className="required-asterisk">*</span>
                                    </IonLabel>
                                    <IonTextarea
                                        placeholder="Describe el aviso"
                                        {...register("contenido", {
                                            required: "El contenido es obligatorio.",
                                        })}
                                        fill="outline"
                                        counter={true}
                                        maxlength={150}
                                        autoGrow={true}
                                        onIonInput={() => clearErrors("contenido")}
                                        className="create-form-input"
                                    />

                                    <ErrorMessage
                                        errors={errors}
                                        name="contenido"
                                        render={({ message }) => (
                                            <div className="error-form">
                                                <IonIcon icon={alertCircleOutline} slot="start" />
                                                <span>{message}</span>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="ion-margin-bottom">
                                    <IonLabel position="stacked" className="create-form-label">
                                        Imagen
                                    </IonLabel>
                                    <IonButton className="ion-upload-img-button" onClick={() => setShowModal(true)}>
                                        <div className="d-flex-items-vertical">
                                            <IonIcon
                                                icon={cloudUploadOutline}
                                                className="icon-upload-button"
                                            ></IonIcon>
                                            <span className="spn-upload-image">
                                                Seleccionar imagen
                                            </span>
                                            <span className="spn-upload-subtitle">Formato JPG</span>
                                        </div></IonButton>
                                    <IonModal
                                        isOpen={showModal}
                                        onDidDismiss={() => setShowModal(false)}
                                        breakpoints={[0, 0.5]}
                                        initialBreakpoint={0.5} // Abierto al 30%
                                        handleBehavior="none" // Desactiva el movimiento del handle
                                        className="select-image-custom-modal"
                                    >
                                        <IonHeader className="ion-no-border" translucent={true}>
                                            <IonToolbar style={{
                                                "--background": "transparent", // Fondo transparente o sin color
                                                "--box-shadow": "none",        // Elimina las sombras
                                                "--border-color": "transparent", // Elimina los bordes
                                                "marginTop": "20px"
                                            }}>
                                                <IonTitle>Seleccionar Imagen</IonTitle>
                                                <IonButtons slot="end">
                                                    <IonButton color="primary" onClick={() => setShowModal(false)}>Cerrar</IonButton>
                                                </IonButtons>
                                            </IonToolbar>
                                        </IonHeader>

                                        <IonContent className="ion-padding">
                                            <p className="font-dark-grey font-18" style={{ "marginTop": "0px" }}>
                                                Selecciona una opción: toma una foto ahora o sube una desde tus archivos</p>
                                            <IonButton style={{
                                                "--padding-top": "30px",
                                                "--padding-bottom": "30px"
                                            }} fill="outline" expand="block" onClick={handleTakePhoto} className="mb-3">
                                                Tomar Foto
                                            </IonButton>
                                            <IonButton style={{
                                                "--padding-top": "30px",
                                                "--padding-bottom": "30px"
                                            }} fill="outline" expand="block" onClick={() => document.getElementById("file-upload")?.click()}>
                                                Subir desde Archivos
                                            </IonButton>

                                            {/* Input oculto para subir archivo */}
                                            <input
                                                type="file"
                                                id="file-upload"
                                                accept="image/jpeg"
                                                style={{ display: "none" }}
                                                onChange={handleImageUpload}
                                            />
                                        </IonContent>
                                    </IonModal>

                                    {imagePreview && (
                                        <div className="image-preview-container">
                                            <img
                                                src={imagePreview}
                                                alt="Previsualización"
                                                className="image-preview"
                                            />
                                            <IonIcon
                                                icon={close}
                                                className="delete-icon"
                                                onClick={() => {
                                                    setImagePreview(null);
                                                    setValue("imgUrl", null);
                                                    const fileInput = document.getElementById(
                                                        "file-upload"
                                                    ) as HTMLInputElement;
                                                    if (fileInput) {
                                                        fileInput.value = "";
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <IonButton
                                    expand="block"
                                    size="large"
                                    type="submit"
                                    className="btn-guardar"
                                    disabled={isSubmitting}
                                >
                                    Guardar
                                </IonButton>
                            </form>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage >
    );
};

export default AvisosCopCreate;

