import { axiosInstance } from "./axiosConfig";
import { IUserRequest } from "../store/ducks/users/types";

export const signIn = (user: IUserRequest) =>
  axiosInstance.post(`/api/auth/login`, user);

export const checkAuth = () =>
  axiosInstance.get(`/api/auth/check-auth`);

export const logoutApi = () =>
  axiosInstance.get(`/api/auth/logout`);