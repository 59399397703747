import React from "react";
import {
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonTitle
} from "@ionic/react";

interface ToolbarMenuProps {
    title: string;
}

const ToolbarMainMenu: React.FC<ToolbarMenuProps> = ({ title }) => {
    

    return (
        <IonToolbar>
            <IonButtons slot="start">
                {/* Botón para abrir el menú */}
                <IonMenuButton autoHide={false} />
            </IonButtons>
            <IonTitle className="font-size-xl">{title}</IonTitle>
        </IonToolbar>
    );
};

export default ToolbarMainMenu;
